
.qc-buttons-wrapper button {
    font-size: 1.4em;
    margin-right: 1em;
    width: 100%;
    height: 55px;
    text-align: center;
}

.qc-buttons-wrapper svg {
    align-self: center;
    margin-right: 0.5em;
}